import { Box, Flex, Grid, GridItem, HStack, Icon, Skeleton, Stat, StatArrow, StatLabel, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Briefcase, GasStation, Person, Truck } from '../../../../Assets';
import AuthContext from '../../Contexts/AdminContext';





const AdminOverviewCard = () => {

    const {adminDashboardCount, isLoadingCount, isErrorCount} = useContext(AuthContext);

    if (isLoadingCount) {
      return (
        <HStack>
            <Skeleton height='120px' width='200px' />
            <Skeleton height='120px' width='200px' />
            <Skeleton height='120px' width='200px' />
            <Skeleton height='120px' width='200px' />
        </HStack>
        )
    } 

    if (isErrorCount) {
      return <div>Error Fetching Data</div>
    }

     const data = [
        {
          title: "LPG Terminals/Depot",
          icon: <GasStation />,
          number: adminDashboardCount?.TotalVendors || 0,
          subT:'No Comparison data',
          route: "/AdminPage/dashboard/lpg-terminals",
          linkname:'View'
        },
        {
          title: "LPG Filling Plants",
          icon: <Person />,
          number: adminDashboardCount?.TotalCustomers || 0,
          subT:'No Comparison data',
          route: "/AdminPage/dashboard/lpg-plants",
          linkname:'View'
        },
        {
          title: "LPG Transporter",
          icon: <Truck />,
          number: adminDashboardCount?.TotalTruckers || 0,
          subT:'No Comparison data',
          route: "/AdminPage/dashboard/lpg-transporter",
          linkname:'View'
        },
        {
          title: "Admin",
          icon: <Briefcase/>,
          number: adminDashboardCount?.TotalAdmin || 0,
          subT:'No Comparison data',
          route: "/AdminPage/dashboard/admin",
          linkname:'View'
        },
       
        
    ];
  return (
      <Box
          h={`100%`}
      >
          <Grid
              templateColumns='repeat(4, 1fr)'
              //   templateRows='repeat(1, 1fr)'
              h={`100%`}
              gap={6}
          >
        {
            data.map(({icon,title,number,subT,route,linkname}) => (
                <GridItem  
                    bg='#fff' key={title} 
                    borderRadius='16px'
                    p='10px'
                    h={'auto'}
                >
                    <Flex 
                        alignItems='center' 
                        justifyContent='space-between' 
                        mb='10px' 
                    >
                        <Text 
                            fontSize='sm' 
                            fontWeight='normal' 
                            color='primary.400' 
                        >
                            {title}
                        </Text>
                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                            {icon}
                        </Icon> 
                
                    </Flex>
                    <Text 
                        fontWeight='bold' 
                        fontSize='2xl' 
                    >
                        {number}
                    </Text>
                    <Flex 
                        alignItems='center' 
                        justifyContent='space-between'  
                    >
                        <Stat >
                            <Flex flexDir='row' alignItems='center' >
                                <StatArrow type='increase' />
                                <StatLabel 
                                    fontSize='0.65rem' 
                                    fontWeight='normal' 
                                    color='primary.400'
                                >
                                    {subT}
                                </StatLabel>
                            </Flex>
                                                
                        </Stat>
                        <Link 
                            to={`${route}`} 
                            style={{
                                color: '#049541', 
                                fontWeight:'600', 
                                fontSize:'0.75rem'  
                            }} 
                        >
                            {linkname}
                        </Link>
                 </Flex>
                </GridItem>
                
            ))
        }
        </Grid>
    </Box>
    // <Flex alignItems='center' justifyContent='space-evenly'>
    //     {Details.map(({icon,title,number,subT,route,linkname})=> (
    //         <Box 
    //             w='300px' 
    //             h='110px' 
    //             key={title}
    //             borderRadius='16px'
    //             bg='#fff'
    //             p='10px'
    //             m='0 10px'
    //         >
    //             <Flex alignItems='center' justifyContent='space-between' mb='10px' >
    //                 <Text fontSize='base' fontWeight='normal' color='primary.400' >{title}</Text>
    //                 <Icon boxSize='25px' viewBox="0 0 50 50" >
    //                     {icon}

    //                 </Icon> 
                
    //             </Flex>
    //             <Text fontWeight='bold' fontSize='2xl' >{number}</Text>
    //             <Flex alignItems='center' justifyContent='space-between'  >
    //                 <Stat >
    //                     <Flex flexDir='row' alignItems='center' >
    //                         <StatArrow type='increase' />
    //                         <StatLabel fontSize='0.65rem' fontWeight='normal' color='primary.400'>{subT}</StatLabel>
    //                     </Flex>
                                            
    //                 </Stat>
    //                 <Link to={`${route}`} style={{color: '#049541', fontWeight:'600', fontSize:'0.875rem'  }} >{linkname}</Link>
    //             </Flex>
    //         </Box>
    //     ))}
    // </Flex>
)}

export default AdminOverviewCard