import { Box, Flex, Grid, GridItem, Image, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import {PagePlant, PageTerminal, PageTransport,} from '../../../Assets'
import Arrow from '../../../Assets/Arrow.svg'

const Services = () => {

  const [isSm] = useMediaQuery(`(max-width: 992px)`);

  return (
    <Box bg={'brand.300'} p={isSm ? 10 : 20}  >
      <Text 
        fontWeight={'semibold'}
        fontSize={['2xl','3.5xl','5xl']} 
        color={'brand.200'} pb={20} 
      >
        Experience the best <br />  LPG Supply 
      </Text> 

      <Grid 
        templateColumns={isSm ? "1fr" : "repeat(3, 1fr)"} // 1 column for small screens, 3 for medium+
        gap={8}
        p={0}
      >
        <GridItem 
          bg="#F3FFF8" 
          border={'1px solid #049541'}
          borderRadius="30px"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            direction="column"
            height="100%" // Ensures Flex container stretches fully
            position="relative" // Required for z-index to work
          >
            {/* Content Box */}
            <Box p={5} zIndex="1"> {/* Keep the text and box behind the image */}
              <Text
                color="primary.200"
                fontWeight="semibold"
                fontSize="2xl"
              >
                LPG Terminals/
                Depot (Vendors)
              </Text>
              <Text 
                fontWeight="medium"
                fontSize="base"
                color="brand.200"
                py={8}
              >
                Terminal Depots leverage EasyGaz to efficiently create and manage LPG products, set competitive pricing, and
                maintain inventory, ensuring a 
                responsive and dynamic presence in the LPG market.
              </Text>
              <Box
                w="31%"
                borderBottom="2px solid #049541"  
                fontWeight="bold"              
                color="border.300"
                cursor="pointer"
                display="flex"
                alignItems="center"
                pb="5px"
                zIndex="1" // Ensures this content remains below the image
              >
                <Text pr={3}>
                  Start Selling
                </Text>
                <Image
                  boxSize="15px"
                  objectFit="cover"
                  src={Arrow}
                />
              </Box>
            </Box>

            {/* Image at the Bottom */}
            <Image    
              borderRadius="30px"         
              boxSize="100%"
              objectFit="cover" 
              src={PageTerminal} 
              mt="auto" // Pushes the image to the bottom
              position="relative" // Allow overlapping
              zIndex="2" // Place image on top of content and border
            />
          </Flex>

        </GridItem>

        <GridItem 
          bg="#F3FFF8" 
          border={'1px solid #049541'}
          borderRadius="30px"
        >
          <Flex
           alignItems="center"
           justifyContent="space-between"
           direction="column"
           height="100%" // Ensures Flex container stretches fully
           position="relative" // Required for z-index to work
          >
            <Box p={5} zIndex="1">
              <Text
                color={'primary.200'}
                fontWeight={'semibold'}
                fontSize={'2xl'}
              >
                LPG Transporter
                (Delivery)
              </Text>
              <Text 
                fontWeight={'medium'}
                fontSize={'base'}
                color={'brand.200'}
                py={8}
              >
                Truckers find a reliable partner in 
                EasyGaz, seamlessly accepting and managing delivery requests, updating delivery statuses, and contributing toa smooth and transparent LPG supply
                chain.
              </Text>
              <Box
                // ml={'20px'} 
                w={'31%'}
                borderBottom={'2px solid #049541'}  
                fontWeight={'bold'}              
                color={'border.300'}
                cursor={'pointer'}
                display={'Flex'}
                alignItems={'center'}
                pb={'5px'}
                zIndex="1" // Ensures this content remains below the image
              >
                <Text pr={3}>
                  Start Selling
                </Text>
                <Image
                  boxSize='15px'
                  objectFit='cover'
                  src={Arrow}
                />
              </Box>
            </Box>
            <Image    
                borderRadius={'30px'}         
                boxSize='100%'
                objectFit='cover' 
                src={PageTransport} 
                mt="auto" // Pushes the image to the bottom
                position="relative" // Allow overlapping
                zIndex="2" // Place image on top of content and border
            /> 
          </Flex>
        </GridItem>

        <GridItem 
          bg="#F3FFF8" 
          border={'1px solid #049541'}
          borderRadius="30px"

        >
          <Flex
             alignItems="center"
             justifyContent="space-between"
             direction="column"
             height="100%" // Ensures Flex container stretches fully
             position="relative" // Required for z-index to work
          >
            <Box p={5} zIndex="1">
              <Text
                color={'primary.200'}
                fontWeight={'semibold'}
                fontSize={'2xl'}
              >
                LPG Filling Plants
                (Customers)
              </Text>
              <Text 
                fontWeight={'medium'}
                fontSize={'base'}
                color={'brand.200'}
                py={8}
              >
                Plant Depots experience convenience at its best, effortlessly placing LPG 
                orders, tracking deliveries in real-time, and managing their accounts with user-friendly interfaces on EasyGaz.
              </Text>
              <Box
                // ml={'20px'} 
                w={'31%'}
                borderBottom={'2px solid #049541'}  
                fontWeight={'bold'}              
                color={'border.300'}
                cursor={'pointer'}
                display={'Flex'}
                alignItems={'center'}
                pb={'5px'}
                zIndex="1" // Ensures this content remains below the image
              >
                <Text pr={3}>
                  Start Selling
                </Text>
                <Image
                  boxSize='15px'
                  objectFit='cover'
                  src={Arrow}
                />
              </Box>
            </Box>
            <Image    
                borderRadius={'30px'}         
                boxSize='100%'
                objectFit='cover' 
                src={PagePlant} 
                mt="auto" // Pushes the image to the bottom
                position="relative" // Allow overlapping
                zIndex="2" // Place image on top of content and border
            /> 
          </Flex>
        </GridItem>

       
      </Grid>


      {/* <Grid 
       h={ isSm ? null : '800px'}
       templateRows={isSm ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)' }
       templateColumns={ isSm ? '1fr' : 'repeat(2, 1fr)' }
       gap={4}
      >
   
        <GridItem 
          rowSpan={isSm ? 1 : 2 } 
          colSpan={ isSm ? 1 : 1 }  
          backgroundImage ={ `url(${PageTerminal})`}
          backgroundPosition={"center"}
          backgroundSize={'cover'}
          backgroundRepeat={"no-repeat"}
          borderRadius={'3xl'} 
          color={'#FFF'}
          display="flex"             
          flexDirection="column"      
          justifyContent="flex-end"
        >
          <Box px={ isMd ? 8 : 15} py={5} >
            
            <Text color={'brand.300'} fontWeight={'bold'} fontSize={ isMd ? 'lg' : '3xl'} >
              LPG Terminals/Depot (Vendors)
            </Text>           
          
            <Text color={'font.300'} py={4} fontSize={ isMd ? 'sm' : 'base'} >
              Terminal Depots leverage EasyGaz to efficiently create and manage LPG products, set competitive pricing, and maintain inventory, ensuring a responsive and dynamic presence in the LPG market.
            </Text>
            <Box
              // ml={'20px'} 
              w={'22%'}
              borderBottom={'2px solid #049541'}  
              fontWeight={'bold'}              
              color={'border.300'}
              cursor={'pointer'}
              display={'Flex'}
              alignItems={'center'}
              pb={'5px'}
              float={'right'}
            >
              <Text pr={3}>
                Start Selling
              </Text>
              <Image
                boxSize='15px'
                objectFit='cover'
                src={Arrow}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem 
          rowSpan={isSm ? 1 : null } 
          colSpan={ isSm ? 1 : 2 }  
          backgroundImage ={ `url(${PagePlant})`}
          backgroundPosition={"center"}
          backgroundSize={'cover'}
          backgroundRepeat={"no-repeat"}
          borderRadius={'3xl'} 
          color={'#FFF'}
          display="flex"             
          flexDirection="column"      
          justifyContent="flex-end"  
        >
          <Box px={ isMd ? 8 : 15} py={5} >
            
            <Text color={'brand.300'} fontWeight={'bold'} fontSize={ isMd ? 'lg' : '3xl'} >
              LPG Filling Plants (Customers)
            </Text>           
          
            <Text color={'font.300'} py={4} fontSize={ isMd ? 'sm' : 'base'} >
              Plant Depots experience convenience at its best, effortlessly placing LPG orders, tracking deliveries in real-time, and managing their accounts with user-friendly interfaces on EasyGaz.             
            </Text>
            <Box
              // ml={'20px'} 
              w={'22%'}
              borderBottom={'2px solid #049541'}  
              fontWeight={'bold'}              
              color={'border.300'}
              cursor={'pointer'}
              display={'Flex'}
              alignItems={'center'}
              pb={'5px'}
              float={'right'}
            >
              <Text pr={3}>
                Start Buying
              </Text>
              <Image
                boxSize='15px'
                objectFit='cover'
                src={Arrow}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem 
          rowSpan={isSm ? 1 : null } 
          colSpan={ isSm ? 1 : 2 }  
          backgroundImage ={ `url(${PageTransport})`}
          backgroundPosition={"center"}
          backgroundSize={'cover'}
          backgroundRepeat={"no-repeat"}
          borderRadius={'3xl'} 
          color={'#FFF'}
          display="flex"             
          flexDirection="column"      
          justifyContent="flex-end"  
        
        >
          <Box px={ isMd ? 8 : 15} py={5} >
            
            <Text color={'brand.300'} fontWeight={'bold'} fontSize={ isMd ? 'lg' : '3xl'} >
              LPG Transporter (Delivery)
            </Text>           
            
            <Text color={'font.300'} py={4} fontSize={ isMd ? 'sm' : 'base'} >
              Truckers find a reliable partner in EasyGaz, seamlessly accepting and managing delivery requests, updating delivery statuses, and contributing to a smooth and transparent LPG supply chain.              
            </Text>
            <Box
              // ml={'20px'} 
              w={'24%'}
              borderBottom={'2px solid #049541'}  
              fontWeight={'bold'}              
              color={'border.300'}
              cursor={'pointer'}
              display={'Flex'}
              alignItems={'center'}
              pb={'5px'}
              float={'right'}
            >
              <Text pr={3}>
                Make Delivery
              </Text>
              <Image
                boxSize='15px'
                objectFit='cover'
                src={Arrow}
              />
            </Box>
          </Box>
        </GridItem>
        {isSm ? null : 
          <GridItem colSpan={4} bg='tomato' />
        }
        
        
      </Grid>
      */}
    </Box>
  )
}

export default Services