import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import Navbar from './Pages/Navbar'
import Home from './Pages/Home'
import About from './Pages/About'
import Services from './Pages/Services'
import Contact from './Pages/Contact'
import AuthContext from '../Admin/Contexts/AdminContext'
import OurCustomers from './Pages/OurCustomers'

const LandingPage = () => { 
  const {landingPageProductData,isLoadingLandingProduct,isErrorLandingProduct} = useContext(AuthContext);

  return (
    <Box className='landPage'  >
        <Navbar/>
        <Home    /> 
        <OurCustomers productData={landingPageProductData} isLoadingProduct={isLoadingLandingProduct} isErrorProduct={isErrorLandingProduct}  />
        <About />
        <Services/>
        <Contact/>
    </Box>
  )
}

export default LandingPage