import { 
    Box, 
    Button, 
    Flex, 
    Heading, 
    HStack, 
    Icon, 
    Image, 
    Text, 
    useMediaQuery 
} from '@chakra-ui/react'
import React from 'react'
import {  
    ContactChat, 
    ContactMail, 
    ContactPhone, 
    Facebook, 
    GoogleMap, 
    Instagram, 
    Linkedin, 
    Twitter, 
} from '../../../Assets'
import Logo from "../../../Assets/Full-Logo.svg"
import { NavLink, useNavigate } from 'react-router-dom'

const Contact = () => {

    const navigate = useNavigate();    
    const [isLg] = useMediaQuery(`(max-width: 1264px)`);
    const [isSm] = useMediaQuery(`(max-width: 992px)`);
    const [isXs] = useMediaQuery(`(max-width: 760px)`);
    const [isXl] = useMediaQuery(`(max-width: 560px)`);
    const direction = isSm ? "column" : "row";
    const vert = isLg ? "column" : "row";

  return (
    <Box pt={40} position={'relative'}  >
        
        <Box backgroundColor={'#7E7E7E'} p={isSm ? 10 : 20}  position={'relative'}   >
            
            <Box
                position="absolute"
                top="-50px" // Adjust to overlap white and grey areas
                left="50%"
                transform={isSm ? "translateX(-50%)" : "translateX(-112%)" }
                bg="primary.100" // Card background color
                color="brand.300"
                borderRadius="40px"
                p={8} // Padding inside the card
                textAlign="left"
                w={{ base: "90%", md: "60%", lg: "40%" }} // Responsive width
            >
                <Heading  fontSize="2xl" mb={4}>
                    Let's Get Started...
                </Heading>
                <Text fontSize={'sm'} fontWeight={'normal'} mb={6}>
                    Onboard With Us Today And Start Your Transactions
                </Text>
                <Button
                    bg="brand.300" 
                    color="primary.100" 
                    p="25px 30px" 
                    h="48px" 
                    border={'1px solid #E8EEFF'}
                    borderRadius={'30px'}
                    // width="40%" 
                    // mr={'auto'}
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'brand.300',
                    color: 'none',}}                  
                    onClick={() => navigate('/user_type') }
                >
                    Get Started
                </Button>
            </Box>
            
            <Box textAlign={ isSm ? 'left' : 'right'} mt={isSm ? '36' : '0'  } >
                <Image
                    ml={'auto'}
                    mr={isSm ? 'auto' : 'none' }
                    src={Logo}
                    alt="Logo" 
                    boxSize={isXl ? '50%' : isSm ?  "30%" : '20%' } // Control the size of the image
                    objectFit="contain" // Ensure the logo scales well
                />
                <Text 
                    w={isXl ? '100%' : isSm ? '50%' : '30%'}
                    ml={'auto'}
                    mr={isSm ? 'auto' : 'none' }
                    color={'brand.300'}
                    fontWeight={'normal'}
                    fontSize={'sm'}
                >
                    EasyGas is your all-in-one solution for
                    streamlined LPG experiences.
                </Text>
            </Box>

            <Flex flexDir={direction} alignItems={'center'} justifyContent={'space-between'} >
                <Box
                    order={isSm ? 2 : 1} 
                    my={4}
                >
                    <Box className='foot-link' >
                        <NavLink style={{paddingLeft:'0px'}} >Home</NavLink>
                        <NavLink>About</NavLink>
                        <NavLink>Services</NavLink>
                        <NavLink>Contact Us</NavLink>
                    </Box>
                    <HStack display={ isXs ? 'none' : 'flex' } alignItems={'center'} mt={5} >
                        <Icon boxSize='50px' viewBox="0 0 50 50" >
                            <Facebook/>
                        </Icon> 
                        <Icon boxSize='50px' viewBox="0 0 50 50" >
                            <Linkedin/>
                        </Icon> 
                        <Icon boxSize='45px' viewBox="0 0 50 50" >
                            <Twitter/>
                        </Icon> 
                        <Icon boxSize='50px' viewBox="0 0 50 50" >
                            <Instagram/>
                        </Icon> 
                    </HStack>
                    <HStack flexDir={vert} alignItems={isLg ? 'flex-start' : 'center'} justifyContent={ isSm ? 'none' : 'none'} py={5} color={'#fff'} > 
                        <Flex alignItems={'center'} mr={isSm ? 5 : null} >
                            <Icon boxSize='40px' viewBox="0 0 50 50" >
                                <ContactPhone/>
                            </Icon> 
                            <Box textAlign={'left'} ml={'5'} >
                                <Text fontWeight={'medium'} fontSize={'lg'}  >Call Us</Text>
                                <Text>(234) 7052906307 </Text>
                            </Box>
                        </Flex>
                        <Flex alignItems={'center'} mr={isSm ? 5 : null } mx={ isLg ? null : 10} >
                            <Icon boxSize='40px' viewBox="0 0 50 50" >
                                <ContactChat/>
                            </Icon> 
                            <Box textAlign={'left'} ml={'5'}  >
                                <Text fontWeight={'medium'} fontSize={'lg'}>Chat With Us</Text>
                                <Text>(234) 8097067857 </Text>
                            </Box>
                        </Flex>
                        <Flex alignItems={'center'} >
                            <Icon boxSize='40px' viewBox="0 0 50 50" >
                                <ContactMail/>
                            </Icon> 
                            <Box textAlign={'left'} ml={'5'}  >
                                <Text fontWeight={'medium'} fontSize={'lg'}>Email Us</Text>
                                <Text>elijah.e@myeasygas.com</Text>
                            </Box>
                        </Flex>
                    </HStack>
                    
                </Box>
                <Box
                    width={ isXl ? "280px" : "400px" } // Adjust as needed
                    height="250px" // Adjust as needed
                    borderRadius="20px"
                    overflow="hidden"
                    boxShadow="md"
                    my={5}
                    order={isSm ? 1 : 2} 
                >
                    {/* Static Map */}
                    <a
                        href="https://www.google.com/maps/dir//Adeyeri+Estate,+18+Irawo-Ogo+St,+Ojodu,+Lagos+101232,+Lagos/@6.6637512,3.2777787,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x103b9157f4000001:0xd9efbb02e08ee1a7!2m2!1d3.3379343!2d6.6422847?authuser=0&entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Image
                            src={GoogleMap}
                            alt="Office Location"
                            width="100%"
                            height="100%"
                            objectFit="cover"
                        />
                    </a>                 
                </Box>
            </Flex>

            <Box borderTop={'1px solid #B5B4B4'} mt={20} textAlign={'center'} >
                <HStack display={ isXs ? 'flex' : 'none' } alignItems={'center'} justifyContent={ 'center'}  mt={5} >
                    <Icon boxSize='50px' viewBox="0 0 50 50" >
                        <Facebook/>
                    </Icon> 
                    <Icon boxSize='50px' viewBox="0 0 50 50" >
                        <Linkedin/>
                    </Icon> 
                    <Icon boxSize='45px' viewBox="0 0 50 50" >
                        <Twitter/>
                    </Icon> 
                    <Icon boxSize='50px' viewBox="0 0 50 50" >
                        <Instagram/>
                    </Icon> 
                </HStack>
                <Text color={'border.200'}  >
                    © 2024 EasyGas. All Rights Reserved.
                </Text>
            </Box>

        </Box>

    </Box>

  )
}

export default Contact