import { Box, HStack, Image, Text,useBreakpointValue, useMediaQuery,  } from '@chakra-ui/react'
import  Logo from '../../../Assets/Logo.svg'
import Slider from 'react-slick';


function SampleNextArrow(props) {
    const { className, style} = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "none" }}
      />
    );
  }
    
  function SamplePrevArrow(props) {
    const { className, style} = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "none" }}
      />
    );
  }


const OurCustomers = ({isLoadingProduct,isErrorProduct,productData}) => {

    const slidesToShow = useBreakpointValue({ base: 1, sm: 1, md: 1, lg: 2, xl: 3 });
    const [isXl] = useMediaQuery(`(max-width: 560px)`);
    const [isSm] = useMediaQuery(`(max-width: 992px)`);


    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: slidesToShow, // Always set this to 3 for your requirement
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true, // Enables center mode
        centerPadding: '0', // Ensures slides fill the container without extra padding
        autoplay: true,
        autoplaySpeed: 2000,
            
    };
    
    function formatPrice(price) {
    const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
    if (!isNaN(numericPrice)) {
        const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
        return `${formattedPrice}`; // Add Naira symbol in front of the formatted price
    }
    return price;
    }

    if (isLoadingProduct) {
        return <div>Loading....</div>
    }

    if (isErrorProduct) {
        return <div>Error Fetching Data</div>
    }
    
     


  return (
    <Box pl={ isXl ? 5 : isSm ? 10 : 20} pr={ isXl ? 5 :  isSm ? 10 : 20} bg={'primary.100'} mb={20}  >
        <Box py={5}  >  
            <Text 
                textAlign={'center'}
                color={'brand.300'}
                fontWeight={'semibold'}
                fontSize={['2xl','3.5xl','5xl']} 
                py={10}
            >
                Our Customers
            </Text>
            <Slider {...settings} className="custom-carousel">            
                {
                    productData && productData.map((data) =>
                    <Box 
                        py={5} 
                        px={isXl ? 0 : 5 } 
                        borderRadius={'20px'} 
                        border={'5px solid #049541'} 
                        bg={'#fff'}
                        key={data.ProductID}
                    > 
                        <HStack alignItems={'center'} justifyContent={'space-evenly'} >
                            <Image        
                                ml={0}    
                                boxSize='70px'
                                objectFit='cover' 
                                src={Logo} 
                            /> 
                            <Box>
                                <Text
                                    fontSize={'2xl'}
                                    color={'brand.200'}
                                    fontWeight={'medium'}
                                    // py={3}
                                    textAlign={'left'}
                                >
                                    {data.VendorName}
                                </Text>
                                <Text
                                    fontSize={'3xl'}
                                    fontWeight={'semibold'}
                                    color={'primary.200'}
                                    py={2}
                                    textAlign={'left'}
                                >
                                    ₦{formatPrice(data.Price)}/MT
                                </Text>
                                <Text
                                    fontSize={'xl'}
                                    color={'brand.200'}
                                    fontWeight={'normal'}
                                    // py={3}
                                    textAlign={'left'}
                                >
                                    Last update {data.DateCreated && (
                                        <>
                                            {data.DateCreated.split('T')[0]}                                        
                                        </>
                                    )}
                                </Text>
                            
                            </Box>        
                        </HStack>
                    </Box>
                    )
                }               
            </Slider>
        </Box>
    </Box>
    )
}

export default OurCustomers