import { 
  Box, 
  Button, 
  Text, 
  useMediaQuery
} from '@chakra-ui/react'
import React from 'react'
import { Herobg } from '../../../Assets'
import { useNavigate } from 'react-router-dom';






const Home = () => {

  const navigate = useNavigate();
  const [isSm] = useMediaQuery(`(max-width: 992px)`);
  const [isXs] = useMediaQuery(`(max-width: 760px)`);
  // const direction = isSm ? "column" : "row";
  // const justContent = isSm ? "center" : "space-between";





  return (
    <Box >
   
      <Box pl={ isSm ? 10 : 20} pr={ isSm ? 10 : 20} pb={isSm ? 10 : 20} >
        <Box
          display="flex"
          flexDirection="column" 
          justifyContent="center" 
          alignItems={isSm ? 'center' : 'flex-start'} 
          py={20} 
          px= { isXs ? 5 : 10}
          height={'100vh'}
          borderRadius={'50px'}
          backgroundImage={Herobg}
          backgroundPosition={'center'}
          backgroundSize={'cover'}
          backgroundRepeat={'no-repeat'}
        >
          <Text 
            fontWeight={'bold'} 
            fontSize={['2xl','4xl','7xl']} 
            color={'brand.300'} 
            lineHeight={['25px','40px','80px']} 
            textAlign={ isSm ? 'center' : 'left'}              
          >
            Unlocking Efficiency <br /> In LPG Supply
          </Text>

          <Box w={isSm ? null : '50%'} textAlign={isSm ? 'center' : null} >
            <Text color={'brand.300'} fontWeight={'medium'} fontSize={['sm', 'lg','xl']} pt={ isSm ? '37px' : '16px'} pb={'36px'}    >
              EasyGas is your all-in-one solution for streamlined LPG experiences. 
            </Text>

            <Button
              bg="primary.200" 
              color="#fff" 
              p="25px 30px" 
              h="48px" 
              borderRadius={'30px'}
              fontWeight={'semibold'}
              // width="40%" 
              // mr={'auto'}
              _focus={{ boxShadow: "none" }} 
              _hover={{bg: 'primary.100',
              color: 'none',}}
              onClick={() => navigate('/user_type') }
            >
              Get Started
            </Button>      

          </Box>
        
        </Box>    


      </Box>

    </Box>
  )
}

export default Home