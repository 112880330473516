import { Box, Grid, GridItem, Icon, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { Aboutbg, LandCart, LandFuel, LandTime, LandTruck,} from '../../../Assets';






const About = () => {

  const [isSm] = useMediaQuery(`(max-width: 992px)`);
  // const [isXs] = useMediaQuery(`(max-width: 760px)`);

  const data = [
    {
      title: "Efficient Project Management",
      icon: <LandFuel />,
      subT:'This ensures timely, safe, and cost-effective execution while adhering to regulations and minimizing risks.',
    },
    {
      title: "Transparent and Timely Delivery",
      icon: <LandTruck />,
      subT:'This ensures clear communication, meeting deadlines, and providing stakeholders with accurate, up-to-date project progress.',
    },
    {
      title: "Seamless Order Experience",
      icon: <LandCart/>,
      subT:'This ensures smooth, hassle-free transactions from ordering to delivery, with clear communication and efficient processing.',
    },
    {
      title: "Real - Time Pricing",
      icon: <LandTime/>,
      subT:'This provides instant, dynamic updates on costs based on market conditions, ensuring accurate and current pricing information.',
    },
   
    
];

  return (
    <Box pl={isSm ? 10 : 20} pr={isSm ? 10 : 20} py={20} 
      backgroundImage={Aboutbg}
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}  
    >
      <Box  mt={'0px'}  textAlign={isSm ? 'center' : null} >
        <Text
          fontWeight={'semibold'}
          fontSize={['2xl','3.5xl','5xl']}      
          color={'brand.300'}     
        >
          Powering Your <br /> LPG Experience
        </Text>

        <Grid
           templateRows={{
            base: "repeat(4, 1fr)", // 4 rows (1 item per row) on small screens
            md: "repeat(2, 1fr)",  // 2 rows on medium screens
          }}
          templateColumns={{
            base: "1fr",           // 1 column on small screens
            md: "repeat(2, 1fr)",  // 2 columns on medium screens
          }}
          gap={4} // Spacing between grid items
          pt={4} // Padding around the grid
        >
          {
             data.map(({icon,title,subT}) => (
              <GridItem 
                color={'brand.300'}
                borderRadius={'30px'}
                bg="rgba(0, 0, 0, 0.2)"
                backdropFilter="blur(20px)"
                border="1px solid rgba(255, 255, 255, 0.3)"
                p={5}
                key={title}
              >
                <Icon boxSize='70px' viewBox="0 0 120 120" >
                  {icon}
                </Icon> 
                <Text
                  fontWeight={'semibold'}
                  fontSize={'xl'}
                  pb={2}
                >
                  {title}
                </Text>

                <Text 
                  fontWeight={'normal'}
                  fontSize={'base'}
                >
                  {subT}
                </Text>
              </GridItem>

            ))
          }
        
        </Grid>

      </Box>

      
    </Box>
  )
}

export default About